@use "../../../master.scss";

.ant-select {
    .ant-select-selector {
        border: none !important;
        .ant-select-selection-item {
            padding-inline-end: 0;
        }
    }
    .ant-select-arrow {
        color: black;
    }
}

.Complete {
    .ant-select-selector {
        color: master.$theme-color;
    }
    .ant-select-arrow {
        color: master.$theme-color;
    }
}
