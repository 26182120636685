.stepDetailItemWrapper {
  .alertWrap {
    background-color: rgba(0, 112, 242, 0.1);
    padding: 20px 22px;
    border-radius: 12px;
  }

  .refenceFormWrapper {
    margin-top: 40px;
  }

  .checkbox-parent {
    display: flex;
    align-items: start;
    gap: 10px;
  }

  .stepDetailForm {
    .buttonWrapper {
      margin-top: 0;
    }
  }
  &.stepZero {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .terms-content-wrapper {
      max-height: calc(100% - 100px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .button-parent {
      display: flex;
      justify-content: flex-end;
      button {
        span {
          font-family: seventyTwoBold;
          font-size: 18px;
        }
      }
    }
  }

  .step5TableWrapper {
    @media (max-width: 980px) {
      overflow: auto;
    }
    .step5Table,
    .step5Table th,
    .step5Table td {
      border: 1px solid rgba(2, 19, 73, 1);
      border-collapse: collapse;
      font-size: 14px;
      line-height: 20.98px;
      font-family: seventyTwoRegular;
      font-weight: 400;
      padding: 3px 8px !important;

      @media (max-width: 980px) {
        width: 630px !important;
      }

      th {
        text-align: left;
      }

      tr td:first-child,
      tr th:first-child {
        text-align: center;
      }
    }
  }

  .disabled > span,
  .disabled > label {
    color: rgba(2, 19, 73, 1);
  }
}
