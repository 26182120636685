@use "../../../master.scss";

.main-header {
  width: calc(100% - 240px);
  padding: 20px 30px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  align-items: center;
  top: 0;
  z-index: 3;
  margin-left: 240px;

  @include master.md {
    width: 100%;
    margin: 0;
  }

  .sidebar-icon {
    position: absolute;
    left: 30px;
    cursor: pointer;
  }

  .notification-parent {
    position: relative;
    .notification-circle {
      position: absolute;
      border: 2px solid #fff;
      background-color: #ff6e91;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      right: 0;
    }
  }
}
