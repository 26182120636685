/* Default button styles */

@use "../../../master.scss";

button.ant-btn {
  font-size: 15px;
  height: 44px;
  width: 100%;
  min-width: 100px;
  border-radius: 12px;
  font-weight: 400;
  background-color: #0070f2;
  // color: master.$theme2-color;

  // &:hover {
  //     // color: master.$theme2-color !important;
  // }
}

.border {
  border: 1px solid master.$theme-color;
}

.icon-but {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media query for small screens */
@media only screen and (max-width: 767px) {
  button.ant-btn {
    font-size: 13px;
    height: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  button.ant-btn {
    font-size: 14px;
    height: 40px;
  }
}

:where(.css-dev-only-do-not-override-pfkssg).ant-btn-primary:disabled {
  color: master.$theme-color;
  background-color: master.$theme-color;
  opacity: 0.5;
}
