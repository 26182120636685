.image-uploader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 141px;
  min-width: 141px;
  max-width: 141px;
  max-height: 141px;
  overflow: hidden;
  border-radius: 50%;
  background: rgba(0, 112, 242, 0.1);
  border: 1px solid #0070f2;
  margin-bottom: 20px;
  input {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  img {
    // position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
