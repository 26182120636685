.ask-question-wrapper {
  min-height: calc(100vh - 150px);
  height: 100%;
  .questions-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: relative;
    .question-item {
      padding: 20px;
      border-radius: 12px 12px 12px 0;
      background: #fff;
      border: 1px solid rgba(217, 217, 217, 0.3);
      cursor: pointer;
      &.selected {
        border-color: #0070f2;
      }
    }
    .ask-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15em;
      @media only screen and (max-width: 750px) {
        margin-top: 9em;
      }

      // position: absolute;
      // bottom: 0;
      // width: 100%;

      button {
        span {
          font-family: "seventyTwoBold";
          font-size: 16px;
        }
      }
    }
  }
}
