@use "../../../../master.scss";

.why-Central-parent {
    .icon {
        background-color: #021349;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        @include master.flex-center;
    }
}
