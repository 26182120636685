.public-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    .left-menu {
        display: flex;
        align-items: center;
        gap: 40px;
        ul {
            display: flex;
            list-style: none;
            gap: 30px;
        }
    }
}
