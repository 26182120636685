@use "../../../../master.scss";

.statusButton {
  padding: 3px 0;
  border-radius: 25px;
  text-align: center;
  width: 84px;

  background-color: master.$theme-light-color;
  p {
    font-family: "Lato";
    color: master.$text-greenish-color;
  }
}
