@use "./master.scss";

html,
body {
  scroll-behavior: smooth;
  min-height: 95%;
  margin: 0;
}

*,
input,
button {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: master.$font-poppins;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  font-size: 4px;
}

.container {
  position: relative;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 1200px;
}
.search-box {
  background: #ffffff;
  border: 1px solid rgba(28, 43, 72, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;

  input {
    border: none;
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    width: 100%;

    &::placeholder {
      font-family: "Lato";
      opacity: 0.5;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(28, 43, 72, 1);
    }
  }

  svg {
    cursor: pointer;
    color: #6f7ced;
    font-size: 18px;
    margin-right: 10px;
  }
}
.c-p {
  cursor: pointer;
}

.separator {
  background-color: rgba(231, 236, 241, 1);
  height: 1px;
  width: 100%;
  margin: 20px 0;
}
.hidden {
  display: none !important;
}
